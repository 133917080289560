<template>
  <i class="v-icon" v-html="iconRaw" aria-hidden="true"></i>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'

const icons = import.meta.glob('../../../icons/**/*.svg', { query: '?raw' })

const iconRaw = ref<string>('')

const props = defineProps<{
  name: string
}>()

const setIcon = async () => {
  const path = `../../../icons/${props.name}.svg`
  const icon = icons[path]

  if (icon) {
    const svg = await icon()
    iconRaw.value = (svg as any).default as string
  }
}

watch(props, () => setIcon(), { immediate: true })
</script>
